import React from "react";

export const Watson = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="40"
      height="40"
      viewBox="0 0 80 80"
    >
      <path
        fill="#bae0bd"
        d="M59.35,59.18c-0.12,0.19-0.23,0.4-0.35,0.59c-2.35,3.17-6.54,4.8-11.49,4.8 c-4.6,0-9.85-1.4-14.82-4.25c-2.39-1.37-4.53-2.99-6.39-4.77c0.13-0.68,0.29-1.39,0.48-2.1c-0.88-0.5-1.71-1.02-2.5-1.58 c-0.11,0.4-0.22,0.79-0.32,1.18c-0.84-1.02-1.59-2.08-2.24-3.17c-2.28-1.99-4.08-4.2-5.28-6.58c0.24,2.06,0.88,4.2,1.95,6.36 c0.42,0.86,0.91,1.7,1.45,2.53c0.24,0.37,0.49,0.74,0.76,1.11c0.8,1.11,1.7,2.19,2.69,3.22c-0.3,2.26-0.31,4.35-0.02,6.19 c-0.54-0.6-1.05-1.23-1.53-1.88c-0.44-0.6-0.85-1.23-1.22-1.87c-0.23-0.38-0.44-0.77-0.64-1.16c-0.14-0.27-0.27-0.54-0.39-0.82 c-0.2-0.43-0.39-0.86-0.55-1.3c-0.1-0.25-0.2-0.5-0.28-0.76c-0.15-0.43-0.29-0.86-0.4-1.3c-0.12-0.4-0.22-0.81-0.31-1.22l-2.66,1 c0.02,0.08,0.04,0.16,0.06,0.24c0.16,0.65,0.35,1.28,0.56,1.91c0.02,0.07,0.05,0.15,0.07,0.22c0.21,0.64,0.46,1.27,0.74,1.89 c0.03,0.08,0.07,0.15,0.1,0.22c0.27,0.63,0.58,1.25,0.91,1.86c0.38,0.7,0.79,1.38,1.25,2.04c2.49,3.69,6,6.78,10.36,8.78 c3.44,1.58,7.06,2.33,10.62,2.33c9.46,0,18.53-5.29,22.89-14.26C61.71,58.84,60.54,59.03,59.35,59.18z M39.97,70.09 c-3.17,0-6.39-0.67-9.45-2.07c-0.65-0.3-1.28-0.64-1.89-0.99c-1.96-1.19-2.94-4.11-2.75-8.08c1.65,1.39,3.45,2.67,5.4,3.79 c5.23,3.01,10.91,4.62,16.18,4.62c0.56,0,1.12-0.02,1.67-0.06c0.76-0.05,1.49-0.15,2.21-0.27C47.9,69.01,43.98,70.09,39.97,70.09z"
      ></path>
      <path
        fill="#a3ccd7"
        d="M63.05 58.25c-.06.12-.13.24-.19.37-1.27.58-2.64 1.05-4.09 1.42.07-.1.16-.18.23-.27.55-.86 1.05-1.75 1.49-2.7 1.95-4.17 2.48-8.64 1.81-12.88-.44 1.2-1.04 2.43-1.81 3.68-.78 1.26-1.7 2.5-2.75 3.7-.68.78-1.42 1.56-2.2 2.29-.38.38-.76.73-1.16 1.08-.38.33-.77.67-1.17.98-.27.23-.54.45-.82.66-.53.41-1.07.82-1.63 1.21-.17.11-.34.23-.51.35-.41.28-.84.56-1.27.84l-1.93-2.1c.39-.24.77-.48 1.14-.72.29-.2.58-.4.87-.61.4-.28.79-.57 1.18-.86.44-.32.87-.66 1.29-1.01.23-.18.45-.37.67-.56.56-.49 1.11-.98 1.62-1.49.75-.73 1.45-1.48 2.09-2.24.82-.98 1.55-1.98 2.18-2.99 2.37-3.84 2.9-7.32 1.53-9.84-.05-.08-.1-.17-.15-.26-1.48-2.41-4.68-3.6-8.74-3.6-2.44 0-5.18.43-8.04 1.28l1.28-3.27c2.35-.56 4.61-.85 6.7-.85 1.23 0 2.39.1 3.49.3-.92-.74-1.91-1.4-2.96-2-.66-.36-1.42-.54-2.28-.54-1.89 0-4.2.87-6.66 2.55-1.98 1.35-3.95 3.14-5.81 5.25-1.85 2.12-3.6 4.56-5.15 7.23-2.17 3.73-3.74 7.57-4.62 11.18-.86-.52-1.69-1.07-2.49-1.63.96-3.5 2.51-7.23 4.67-10.95 3.31-5.69 7.5-10.45 11.81-13.39 1.39-.95 2.74-1.67 4.04-2.17-1.55-.34-3.12-.51-4.7-.51-.32 0-.63.03-.95.05l.53-2.83c.14 0 .28-.02.42-.02 0 0 0 0 .01 0 3.63 0 7.25.79 10.64 2.35C63.37 30.58 68.93 45.61 63.05 58.25zM22.264 50.761c-.503-.764-.954-1.544-1.346-2.337-2.191-4.436-2.347-8.688-.44-11.972 1.907-3.283 5.689-5.275 10.648-5.607.804-.054 1.627-.048 2.456-.014l2.587-2.587c-1.201-.157-2.393-.249-3.563-.249 0 0 0 0-.001 0-.561 0-1.118.018-1.669.055-5.927.397-10.508 2.883-12.899 7.001-1.778 3.062-2.163 6.717-1.168 10.569C18.418 47.422 20.225 49.151 22.264 50.761z"
      ></path>
      <path
        fill="#fff"
        d="M18.26 53.62c.11.44.25.87.4 1.3-.76.29-1.7.51-2.75.63-.21-.63-.4-1.26-.56-1.91-.02-.08-.04-.16-.06-.24l2.66-1C18.04 52.81 18.14 53.22 18.26 53.62zM20.6 53.3c-.27-.37-.52-.74-.76-1.11.38.22.64.49.73.78C20.6 53.08 20.61 53.19 20.6 53.3z"
      ></path>
      <path
        fill="#e8f5e9"
        d="M19.49,56.98c-0.76,0.31-1.71,0.54-2.77,0.68c-0.28-0.62-0.53-1.25-0.74-1.89 c-0.24-0.7-0.45-1.41-0.63-2.13c1.03-0.12,2.03-0.12,2.91-0.02c0.18,0.7,0.41,1.39,0.68,2.06C19.1,56.12,19.29,56.55,19.49,56.98z"
      ></path>
      <path
        fill="#d1ead3"
        d="M20.52,58.96c-0.74,0.34-1.7,0.62-2.79,0.78c-0.33-0.61-0.64-1.23-0.91-1.86 c-0.32-0.69-0.6-1.4-0.84-2.11c1.04-0.14,2.06-0.16,2.96-0.09c0.27,0.72,0.59,1.43,0.94,2.12C20.08,58.19,20.29,58.58,20.52,58.96z"
      ></path>
      <path
        fill="#bae0bd"
        d="M21.74,60.83c-0.69,0.39-1.64,0.73-2.76,0.95c-0.85-1.24-1.57-2.54-2.16-3.9 c1.08-0.16,2.14-0.18,3.06-0.08C20.42,58.86,21.04,59.87,21.74,60.83z"
      ></path>
      <path
        fill="#5e9c76"
        d="M49.029 59.544c-.072-.004-.144-.006-.215-.01l.085.093L49.029 59.544zM24.207 54.138l.239-.963c.083-.332.178-.668.271-1.004-.296-.199-.584-.402-.868-.607-.038.132-.081.266-.118.398-.268-.35-.521-.702-.763-1.055-.866-.672-1.67-1.376-2.415-2.107.776 1.54 1.789 3.077 3.023 4.572L24.207 54.138z"
      ></path>
      <path
        fill="#5e9c76"
        d="M59.417,60.039c0.202-0.313,0.383-0.63,0.567-0.947c-0.416,0.056-0.835,0.106-1.256,0.154 c-0.047,0.074-0.087,0.151-0.134,0.225c-2.189,2.968-6.125,4.603-11.081,4.603c-0.001,0-0.002,0-0.002,0 c-4.689,0-9.864-1.488-14.571-4.189c-2.215-1.271-4.266-2.787-6.098-4.505c0.108-0.552,0.238-1.111,0.38-1.673 c-0.303-0.169-0.599-0.343-0.893-0.518c-0.2,0.765-0.381,1.526-0.521,2.274l-0.05,0.267l0.196,0.187 c1.94,1.851,4.123,3.478,6.488,4.835c4.856,2.787,10.208,4.322,15.069,4.322c0.001,0,0.001,0,0.002,0 C52.795,65.073,57.017,63.294,59.417,60.039z"
      ></path>
      <path
        fill="#5e9c76"
        d="M28.383,67.462c0.691,0.4,1.322,0.73,1.926,1.008c3.058,1.407,6.307,2.12,9.656,2.12 c4.056,0,8.076-1.082,11.626-3.127l2.407-1.387l-2.739,0.46c-0.81,0.136-1.498,0.221-2.165,0.265 c-5.604,0.372-11.909-1.248-17.563-4.493c-1.857-1.066-3.649-2.325-5.326-3.742l-0.772-0.653l-0.05,1.01 C25.175,63.133,26.238,66.164,28.383,67.462z M31.033,63.177c5.64,3.236,11.917,4.913,17.577,4.654 c-2.739,1.157-5.682,1.76-8.645,1.76c-3.204,0-6.313-0.683-9.238-2.028c-0.576-0.265-1.179-0.581-1.835-0.96 c-1.618-0.979-2.521-3.362-2.534-6.614C27.847,61.178,29.416,62.249,31.033,63.177z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M36.17 28.24l-2.59 2.59h-.04c-.66-.02-1.29-.04-1.93 0-.16 0-.32.01-.48.02-.22.02-.45.03-.67.05-.19.03-.38.05-.57.07-.4.05-.8.12-1.18.2-.55.11-1.08.24-1.59.4-.49.14-.96.31-1.41.5-.53.22-1.03.47-1.51.75-.67-.7-1.3-1.41-1.89-2.13.87-.55 1.82-1.03 2.84-1.42.76-.29 1.55-.53 2.38-.71.81-.2 1.66-.34 2.54-.42.19-.03.39-.05.59-.06.09-.02.18-.02.28-.03.54-.04 1.09-.05 1.65-.05.01 0 .01 0 .02 0h.2c.65.01 1.31.04 1.97.1C35.24 28.13 35.7 28.18 36.17 28.24zM65.31 45.68c-.9.25-1.83.45-2.79.61-.04-.71-.11-1.41-.22-2.1-.27.75-.62 1.51-1.02 2.28-1.1.14-2.24.22-3.4.25.07-.11.14-.21.21-.32 2.37-3.84 2.9-7.32 1.53-9.84-.05-.08-.1-.17-.15-.26-1.05-1.71-2.96-2.8-5.44-3.3-.69-.14-1.43-.23-2.21-.26-.35-.03-.72-.04-1.09-.04-.38 0-.76.01-1.15.03-.32.02-.65.04-.98.08-.42.04-.84.09-1.27.15-.29.04-.59.08-.89.13-.71.13-1.45.28-2.19.47-.51.12-1.04.26-1.56.42l1.28-3.27c.49-.12.98-.22 1.47-.31.47-.09.95-.17 1.41-.24.27-.04.54-.08.8-.11.48-.06.95-.1 1.41-.13.22-.02.44-.03.66-.04.32-.01.64-.02.95-.02.24 0 .47 0 .7.02.83.02 1.63.09 2.4.22.13.01.26.04.39.06-.17-.14-.34-.27-.52-.4-.65-.49-1.33-.94-2.04-1.36-.13-.09-.27-.16-.4-.24-.66-.36-1.42-.54-2.28-.54-1.89 0-4.2.87-6.66 2.55-3.24 2.21-6.42 5.59-9.18 9.66-.81-.49-1.6-1-2.36-1.52 2.95-4.38 6.41-8.03 9.95-10.45.7-.48 1.39-.9 2.07-1.26.3-.16.59-.3.88-.44.36-.18.73-.33 1.09-.47-.37-.08-.74-.15-1.11-.21-.21-.03-.42-.07-.62-.09-.47-.07-.93-.11-1.39-.14-.41-.04-.84-.06-1.26-.06-.11-.01-.21-.01-.32-.01s-.22 0-.32.01c-.21.01-.42.03-.63.04l.53-2.83c.14 0 .28-.02.42-.02 0 0 0 0 .01 0 .13 0 .25 0 .38.01.79.01 1.58.06 2.36.15.11.01.23.03.34.04.63.07 1.25.17 1.88.3.04.01.09.02.13.03.66.13 1.3.28 1.95.48.63.18 1.26.39 1.88.63.58.21 1.15.45 1.72.71C59.28 28.69 64.6 36.88 65.31 45.68z"
      ></path>
      <path
        fill="#fff"
        d="M47.107,33.03c-0.29,0.04-0.59,0.08-0.89,0.13c-0.71,0.13-1.45,0.28-2.19,0.47 c-0.51,0.12-1.04,0.26-1.56,0.42l1.28-3.27c0.49-0.12,0.98-0.22,1.47-0.31c0.47-0.09,0.95-0.17,1.41-0.24 C46.987,30.91,47.167,31.9,47.107,33.03z"
      ></path>
      <path
        fill="#d8e7fa"
        d="M49.58,32.73c-0.32,0.02-0.65,0.04-0.98,0.08c-0.7,0.06-1.42,0.16-2.16,0.28 c-0.71,0.13-1.45,0.28-2.19,0.47c0.21-1.22,0.64-2.33,1.19-3.16c0.75-0.15,1.49-0.26,2.21-0.35c0.48-0.06,0.95-0.1,1.41-0.13 C49.43,30.6,49.63,31.59,49.58,32.73z"
      ></path>
      <path
        fill="#b2cff5"
        d="M51.82,32.74c-0.35-0.03-0.72-0.04-1.09-0.04c-0.69,0-1.4,0.03-2.13,0.11 c-0.7,0.06-1.42,0.16-2.16,0.28c0.22-1.18,0.66-2.25,1.21-3.04c0.71-0.09,1.4-0.14,2.07-0.17c0.32-0.01,0.64-0.02,0.95-0.02 c0.24,0,0.47,0,0.7,0.02C51.71,30.61,51.88,31.61,51.82,32.74z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M53.64 29.76c-.65-.49-1.33-.94-2.04-1.36.05-.02.1-.03.15-.03C52.56 28.3 53.23 28.84 53.64 29.76zM54.04 32.94c0 .02 0 .04-.01.06-1.01-.2-2.12-.3-3.3-.3-.69 0-1.4.03-2.13.11.19-1.13.6-2.15 1.12-2.93.32-.01.64-.02.95-.02 1.09 0 2.12.08 3.1.24C54.04 30.87 54.15 31.85 54.04 32.94z"
      ></path>
      <path
        fill="#fff"
        d="M50.465,58.029c-0.17,0.11-0.34,0.23-0.51,0.35c-0.41,0.28-0.84,0.56-1.27,0.84l-1.93-2.1 c0.39-0.24,0.77-0.48,1.14-0.72c0.29-0.2,0.58-0.4,0.87-0.61c0.03,0.04,0.07,0.08,0.11,0.12 C49.525,56.599,50.075,57.339,50.465,58.029z"
      ></path>
      <path
        fill="#e0eef2"
        d="M53.21,55.92c-0.27,0.23-0.54,0.45-0.82,0.66c-0.69,0.54-1.4,1.06-2.14,1.56 c-0.39-0.28-0.8-0.62-1.19-1.01c-0.32-0.31-0.61-0.64-0.87-0.97c0.71-0.48,1.39-0.97,2.05-1.47c0.44-0.32,0.87-0.66,1.29-1.01 C52.24,54.4,52.82,55.18,53.21,55.92z"
      ></path>
      <path
        fill="#c2dde4"
        d="M55.54,53.86c-0.38,0.38-0.76,0.73-1.16,1.08c-0.64,0.56-1.3,1.11-1.99,1.64 c-0.42-0.26-0.85-0.59-1.27-0.97c-0.32-0.29-0.61-0.6-0.88-0.92c0.68-0.5,1.34-1.03,1.96-1.57c0.56-0.49,1.11-0.98,1.62-1.49 C54.56,52.32,55.14,53.11,55.54,53.86z"
      ></path>
      <path
        fill="#a3ccd7"
        d="M57.74,51.57c-1.01,1.16-2.13,2.29-3.36,3.37c-0.4-0.22-0.81-0.51-1.21-0.85 c-0.36-0.3-0.68-0.63-0.97-0.97c1.38-1.19,2.63-2.44,3.71-3.73c0.02,0.02,0.05,0.04,0.07,0.06C56.72,50.09,57.33,50.83,57.74,51.57z"
      ></path>
      <path
        fill="#fff"
        d="M36.574,28.375l-2.59,2.59h-0.04c-0.66-0.02-1.29-0.04-1.93,0c-0.16,0-0.32,0.01-0.48,0.02 c-0.22,0.02-0.45,0.03-0.67,0.05c0.27-0.75,0.86-1.59,1.69-2.35c0.22-0.2,0.44-0.38,0.66-0.55c0.65,0.01,1.31,0.04,1.97,0.1 C35.644,28.265,36.104,28.315,36.574,28.375z"
      ></path>
      <path
        fill="#d8e7fa"
        d="M34.78,28.1c-0.09,0.73-0.44,1.59-1.02,2.42c-0.07,0.11-0.15,0.21-0.22,0.31 c-0.66-0.02-1.29-0.04-1.93,0c-0.16,0-0.32,0.01-0.48,0.02c-0.42,0.03-0.84,0.07-1.24,0.12c-0.4,0.05-0.8,0.12-1.18,0.2 c0.05-0.77,0.4-1.69,1.03-2.59c0.11-0.15,0.22-0.3,0.33-0.44c0.19-0.03,0.39-0.05,0.59-0.06c0.09-0.02,0.18-0.02,0.28-0.03 c0.54-0.04,1.09-0.05,1.65-0.05c0.01,0,0.01,0,0.02,0C33.33,28,34.05,28.03,34.78,28.1z"
      ></path>
      <path
        fill="#b2cff5"
        d="M32.59,28c-0.04,0.53-0.17,1.12-0.41,1.72c-0.16,0.4-0.35,0.77-0.57,1.11 c-0.16,0-0.32,0.01-0.48,0.02c-0.42,0.03-0.84,0.07-1.24,0.12c-0.99,0.13-1.91,0.33-2.77,0.6c-0.18-0.78-0.07-1.82,0.36-2.89 c0.02-0.04,0.03-0.08,0.05-0.12c0.99-0.24,2.04-0.4,3.13-0.48c0.09-0.02,0.18-0.02,0.28-0.03C31.48,28.01,32.03,28,32.59,28z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M30.66,28.08c0,0.27-0.02,0.55-0.07,0.84c-0.12,0.75-0.36,1.45-0.7,2.05 c-1.54,0.2-2.93,0.57-4.18,1.1c-0.44-0.68-0.66-1.68-0.56-2.8C26.81,28.64,28.65,28.23,30.66,28.08z"
      ></path>
      <path
        fill="#fff"
        d="M42.972,22.568c-0.12,0.25-0.27,0.51-0.46,0.76c-0.62,0.85-1.48,1.51-2.31,1.85 c-0.11-0.01-0.21-0.01-0.32-0.01s-0.22,0-0.32,0.01c-0.21,0.01-0.42,0.03-0.63,0.04l0.53-2.83c0.14,0,0.28-0.02,0.42-0.02 c0,0,0,0,0.01,0c0.13,0,0.25,0,0.38,0.01c0.79,0.01,1.58,0.06,2.36,0.15C42.742,22.538,42.862,22.558,42.972,22.568z"
      ></path>
      <path
        fill="#d8e7fa"
        d="M45.11,22.91c-0.12,0.31-0.29,0.62-0.51,0.93c-0.45,0.64-1.02,1.17-1.62,1.55 c-0.47-0.07-0.93-0.11-1.39-0.14c-0.52-0.05-1.05-0.07-1.58-0.07c-0.11,0-0.22,0-0.32,0.01c-0.27-0.69-0.06-1.72,0.66-2.74 c0.01-0.02,0.03-0.04,0.05-0.06c0.79,0.01,1.58,0.06,2.36,0.15c0.74,0.08,1.48,0.19,2.22,0.34C45.02,22.89,45.07,22.9,45.11,22.91z"
      ></path>
      <path
        fill="#b2cff5"
        d="M47.06,23.39c-0.12,0.31-0.29,0.63-0.5,0.95c-0.98,1.47-2.65,2.45-3.82,2.26 c0.3-0.16,0.59-0.3,0.88-0.44c0.36-0.18,0.73-0.33,1.09-0.47c-0.37-0.08-0.74-0.15-1.11-0.21c-0.67-0.11-1.34-0.19-2.01-0.23 c-0.04-0.61,0.2-1.36,0.71-2.12c0.14-0.21,0.29-0.41,0.46-0.59c0.74,0.08,1.48,0.19,2.22,0.34C45.68,23.02,46.37,23.18,47.06,23.39z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M48.94,24.02c-0.1,0.27-0.24,0.54-0.42,0.82c-1,1.57-2.77,2.61-3.95,2.33 c-0.52-0.13-0.84-0.49-0.95-1.01c0.36-0.18,0.73-0.33,1.09-0.47c-0.37-0.08-0.74-0.15-1.11-0.21c0.06-0.51,0.27-1.09,0.65-1.68 c0.21-0.33,0.46-0.64,0.73-0.92C46.32,23.14,47.64,23.52,48.94,24.02z"
      ></path>
      <path
        fill="#a3ccd7"
        d="M4.689,27.377c-0.126,0.217-0.187,0.454-0.189,0.688v0.021c0.003,0.48,0.256,0.947,0.705,1.204 l6.757,3.878c0.222,0.127,0.464,0.188,0.703,0.188c0.487,0,0.96-0.251,1.221-0.7c0.389-0.67,0.158-1.526-0.516-1.913l-6.757-3.878 c-0.222-0.127-0.464-0.188-0.703-0.188C5.424,26.676,4.95,26.927,4.689,27.377"
      ></path>
      <path
        fill="#7496c4"
        d="M12.664,33.855c-0.333,0-0.662-0.088-0.952-0.254l-6.756-3.878C4.371,29.387,4.005,28.761,4,28.089 c0.003-0.356,0.091-0.68,0.256-0.963l0,0c0.506-0.873,1.724-1.202,2.605-0.695l6.757,3.878c0.442,0.254,0.759,0.665,0.891,1.156 c0.131,0.49,0.063,1.002-0.191,1.442C13.977,33.492,13.344,33.855,12.664,33.855z M5.91,27.176c-0.325,0-0.627,0.173-0.789,0.451 l0,0C5.042,27.764,5.001,27.912,5,28.069c0.002,0.331,0.176,0.627,0.454,0.787l6.756,3.878c0.425,0.244,1.001,0.087,1.243-0.33 c0.12-0.207,0.152-0.449,0.09-0.68c-0.063-0.233-0.212-0.427-0.423-0.548l-6.757-3.878C6.224,27.217,6.071,27.176,5.91,27.176z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M20.317,12.352c-0.239,0-0.481,0.06-0.703,0.188c-0.674,0.387-0.905,1.243-0.516,1.913l3.901,6.717 c0.261,0.449,0.734,0.701,1.221,0.701c0.239,0,0.481-0.061,0.703-0.188c0.674-0.387,0.905-1.243,0.516-1.913l-3.901-6.717 C21.277,12.603,20.804,12.352,20.317,12.352C20.317,12.352,20.317,12.352,20.317,12.352"
      ></path>
      <path
        fill="#4e7ab5"
        d="M24.22,22.37c-0.68,0-1.314-0.364-1.654-0.949l-3.9-6.717c-0.255-0.438-0.323-0.95-0.192-1.44 c0.132-0.492,0.448-0.902,0.891-1.157c0.88-0.508,2.099-0.178,2.605,0.695l3.9,6.717c0.255,0.439,0.323,0.951,0.192,1.441 c-0.132,0.492-0.448,0.903-0.891,1.157C24.881,22.282,24.552,22.37,24.22,22.37z M20.316,12.852c-0.158,0-0.315,0.042-0.454,0.122 c-0.21,0.121-0.361,0.315-0.423,0.548c-0.062,0.231-0.029,0.472,0.091,0.679l3.901,6.718c0.24,0.414,0.819,0.572,1.243,0.33 c0.21-0.12,0.36-0.315,0.422-0.548c0.062-0.231,0.03-0.473-0.09-0.68l-3.901-6.717C20.943,13.024,20.642,12.852,20.316,12.852 L20.316,12.852z"
      ></path>
      <path
        fill="#7496c4"
        d="M20.91,36.703c0.874-1.505,2.178-2.718,3.817-3.617l-0.626-0.792 c-1.728,0.977-3.114,2.285-4.056,3.906c-1.994,3.433-1.843,7.853,0.425,12.444c0.189,0.383,0.395,0.766,0.614,1.148 c0.798,0.678,1.633,1.339,2.507,1.977c-0.886-1.178-1.635-2.373-2.225-3.568C19.252,43.921,19.09,39.837,20.91,36.703z"
      ></path>
      <path
        fill="#7496c4"
        d="M22.347,30.077c-1.988,1.198-3.609,2.774-4.742,4.724c-1.695,2.919-2.173,6.347-1.445,9.968 c0.466,0.58,0.961,1.153,1.48,1.719c-1.302-4.089-1.031-7.98,0.83-11.185c1.065-1.835,2.604-3.316,4.501-4.436L22.347,30.077z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M38.592,8.509v7.756c0,0.773,0.631,1.4,1.409,1.4c0.778,0,1.409-0.627,1.409-1.4V8.509 c0-0.773-0.631-1.4-1.409-1.4C39.223,7.109,38.592,7.736,38.592,8.509"
      ></path>
      <path
        fill="#4e7ab5"
        d="M40,18.166c-1.052,0-1.909-0.853-1.909-1.9V8.509c0-1.048,0.856-1.9,1.909-1.9s1.909,0.853,1.909,1.9 v7.756C41.909,17.313,41.053,18.166,40,18.166z M40,7.609c-0.501,0-0.909,0.404-0.909,0.9v7.756c0,0.497,0.408,0.9,0.909,0.9 s0.909-0.404,0.909-0.9V8.509C40.909,8.013,40.501,7.609,40,7.609z"
      ></path>
      <path
        fill="#7496c4"
        d="M64.764 45.091c.47 4.393-.258 8.841-2.168 12.945-.142.304-.303.596-.456.893.494-.204.977-.42 1.442-.653 1.929-4.228 2.659-8.799 2.173-13.313L64.764 45.091zM31.154 36.791c-.958 1.332-1.872 2.738-2.725 4.208-2.071 3.564-3.675 7.307-4.676 10.891.287.207.581.409.876.612.97-3.607 2.569-7.394 4.665-11.001.811-1.396 1.675-2.734 2.581-4.002L31.154 36.791z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M58.463,13.052l-3.901,6.717c-0.389,0.67-0.158,1.526,0.516,1.913 c0.222,0.127,0.464,0.188,0.703,0.188c0.487,0,0.96-0.251,1.221-0.7l3.901-6.717c0.389-0.67,0.158-1.526-0.516-1.913 c-0.222-0.127-0.464-0.188-0.703-0.188C59.198,12.352,58.724,12.603,58.463,13.052"
      ></path>
      <path
        fill="#4e7ab5"
        d="M55.781,22.37c-0.333,0-0.662-0.088-0.952-0.253c-0.443-0.254-0.759-0.665-0.891-1.156 c-0.131-0.49-0.063-1.002,0.191-1.442l3.901-6.717c0.506-0.873,1.725-1.201,2.605-0.695c0.442,0.254,0.759,0.665,0.891,1.156 c0.131,0.49,0.063,1.002-0.191,1.442l-3.901,6.717C57.094,22.006,56.46,22.37,55.781,22.37z M58.895,13.304l-3.901,6.716 c-0.12,0.208-0.152,0.449-0.09,0.681c0.063,0.233,0.212,0.427,0.423,0.548c0.421,0.24,1.001,0.084,1.243-0.331l3.901-6.716 c0.12-0.208,0.152-0.449,0.09-0.681c-0.063-0.233-0.212-0.427-0.423-0.548C59.716,12.73,59.136,12.889,58.895,13.304L58.895,13.304z"
      ></path>
      <path
        fill="#7496c4"
        d="M33.329,38.578c-0.862,1.21-1.687,2.487-2.462,3.821c-2.148,3.699-3.728,7.518-4.637,11.159 c0.294,0.184,0.595,0.363,0.895,0.542c0.873-3.64,2.446-7.478,4.607-11.2c0.744-1.282,1.536-2.508,2.362-3.672L33.329,38.578z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M74.09,26.676c-0.239,0-0.481,0.061-0.703,0.188l-6.756,3.878c-0.674,0.387-0.905,1.243-0.516,1.913 c0.261,0.449,0.734,0.7,1.221,0.7c0.239,0,0.481-0.061,0.703-0.188l6.756-3.878c0.443-0.254,0.694-0.711,0.704-1.184v-0.06 c-0.005-0.228-0.066-0.458-0.189-0.669C75.051,26.927,74.577,26.676,74.09,26.676C74.09,26.676,74.09,26.676,74.09,26.676"
      ></path>
      <path
        fill="#7496c4"
        d="M58.98 45.84c-.15.28-.29.55-.46.83-.61.99-1.38 2-2.27 3.02-.65.75-1.37 1.5-2.14 2.23-.53.51-1.07 1.01-1.64 1.5-.22.19-.43.37-.65.56-.42.35-.85.69-1.29 1.03-.38.31-.77.6-1.16.88-.29.22-.58.43-.88.63-.46.32-.93.63-1.4.93l-.55-.85.24-.15c.37-.23.73-.46 1.09-.71.28-.18.55-.36.82-.56.42-.29.84-.59 1.24-.9.42-.31.82-.62 1.22-.96.25-.19.5-.4.74-.61.54-.46 1.06-.93 1.55-1.42.75-.72 1.44-1.46 2.07-2.21.82-.97 1.54-1.95 2.15-2.94.03-.05.05-.09.08-.14L58.98 45.84zM60.95 57.28c-.4.85-.88 1.71-1.41 2.55-.52.15-1.06.29-1.6.41.23-.25.45-.5.65-.77.55-.85 1.04-1.73 1.45-2.61 1.57-3.38 2.25-7.06 1.98-10.74-.32.67-.69 1.35-1.1 2.01-.81 1.32-1.81 2.63-2.94 3.91-.69.77-1.44 1.54-2.23 2.28-.3.29-.61.57-.92.85-.45.41-.92.81-1.41 1.19-.19.16-.39.32-.59.47-.6.48-1.2.94-1.84 1.38-.1.08-.2.15-.3.22-.58.41-1.18.81-1.79 1.2l-.68-.76.85-.54c.26-.17.51-.33.76-.52.23-.15.45-.3.67-.46.5-.34.98-.69 1.45-1.06.35-.27.68-.53 1.01-.8.34-.27.66-.55.98-.83.46-.4.91-.81 1.34-1.24.78-.74 1.51-1.51 2.19-2.3 1-1.15 1.87-2.33 2.6-3.52.42-.67.78-1.36 1.11-2.04l1.76-.23C63.37 49.42 62.69 53.53 60.95 57.28z"
      ></path>
      <path
        fill="#4e7ab5"
        d="M67.336 33.855c-.68 0-1.313-.364-1.653-.949-.255-.44-.323-.952-.192-1.442.132-.491.448-.902.891-1.156l6.756-3.878c.88-.506 2.099-.178 2.605.694.161.278.25.592.256.91-.015.752-.372 1.353-.955 1.688l-6.756 3.878C67.999 33.768 67.67 33.855 67.336 33.855zM74.09 27.176c-.159 0-.316.042-.454.122l-6.756 3.878c-.21.121-.36.315-.423.548-.062.231-.03.473.091.68.241.415.821.571 1.243.33l6.756-3.878c.277-.159.446-.444.453-.761-.003-.188-.045-.336-.121-.467C74.717 27.349 74.415 27.176 74.09 27.176zM62.496 42.218l-.663 1.801c-.307.835-.696 1.678-1.151 2.518.406-.044.808-.094 1.205-.152.044-.087.093-.174.135-.261.006.08.005.159.01.238.332-.05.662-.102.987-.162-.046-.696-.112-1.392-.222-2.086L62.496 42.218z"
      ></path>
      <path
        fill="#e0eef2"
        d="M48.49 56.52c-.46.32-.93.63-1.4.93l-.55-.85.24-.15c.37-.23.73-.46 1.09-.71.28-.18.55-.36.82-.56.16.15.32.32.48.49.07.07.14.15.2.22C49.08 56.11 48.79 56.32 48.49 56.52zM50.99 58.21c-.1.08-.2.15-.3.22-.58.41-1.18.81-1.79 1.2l-.68-.76.85-.54c.26-.17.51-.33.76-.52.23-.15.45-.3.67-.46C50.69 57.64 50.85 57.93 50.99 58.21z"
      ></path>
      <path
        fill="#c2dde4"
        d="M50.53 55.01c-.66.53-1.34 1.03-2.04 1.51-.22-.26-.43-.52-.62-.78.71-.46 1.4-.95 2.06-1.46.42-.31.82-.62 1.22-.96.1.08.19.17.29.27.13.13.26.26.38.39C51.4 54.33 50.97 54.67 50.53 55.01zM53.42 56.36c-.19.16-.39.32-.59.47-.69.56-1.4 1.09-2.14 1.6-.28-.16-.57-.37-.86-.62.73-.48 1.44-.99 2.12-1.52.35-.27.68-.53 1.01-.8C53.14 55.78 53.3 56.08 53.42 56.36z"
      ></path>
      <path
        fill="#a3ccd7"
        d="M52.47 53.42c-.63.55-1.27 1.08-1.94 1.59-.22-.24-.42-.48-.6-.73.68-.5 1.34-1.03 1.96-1.57.54-.46 1.06-.93 1.55-1.42.09.07.18.15.27.23.14.13.28.26.4.4C53.58 52.43 53.04 52.93 52.47 53.42zM55.75 54.32c-.3.29-.61.57-.92.85-.64.57-1.31 1.13-2 1.66-.29-.14-.58-.33-.88-.54.69-.53 1.35-1.07 1.99-1.63.46-.4.91-.81 1.34-1.24C55.47 53.72 55.63 54.03 55.75 54.32z"
      ></path>
      <path
        fill="#7496c4"
        d="M52.47 53.42c-.21-.23-.41-.47-.58-.71 1.35-1.16 2.57-2.38 3.62-3.63.16.11.32.24.47.37.09.08.18.16.27.24C55.15 50.96 53.86 52.22 52.47 53.42zM57.98 52.04c-.96 1.07-2.02 2.12-3.15 3.13-.29-.13-.59-.3-.89-.51 1.3-1.13 2.48-2.32 3.53-3.54C57.67 51.42 57.84 51.73 57.98 52.04z"
      ></path>
      <path
        fill="#e8f5e9"
        d="M15.88 53.71c.15.6.32 1.2.53 1.78-.2.03-.41.06-.63.08-.13.01-.27.03-.4.03-.2-.62-.38-1.25-.53-1.89-.02-.06-.04-.13-.05-.19l-.1-.43.94-.35.21.85L15.88 53.71zM18.79 53.69c.1.35.21.69.33 1.03v.01c-.28.12-.59.24-.93.35-.18-.49-.33-.99-.47-1.5-.05-.17-.09-.34-.13-.51l-.29-.96 1.01-.38.12.57C18.53 52.77 18.65 53.23 18.79 53.69z"
      ></path>
      <path
        fill="#d1ead3"
        d="M16.49 55.72c.21.64.46 1.26.74 1.86-.18.03-.36.06-.54.08-.17.02-.34.04-.51.05-.27-.61-.5-1.23-.72-1.86-.23-.7-.44-1.41-.61-2.14.13-.03.26-.04.39-.06.21-.02.41-.04.61-.06l.03.12C16.05 54.39 16.25 55.06 16.49 55.72zM19.94 56.78c-.27.14-.58.26-.92.37-.24-.49-.45-.99-.63-1.5-.26-.68-.48-1.37-.67-2.07.37.02.73.06 1.07.11.2.7.44 1.39.71 2.07C19.64 56.1 19.79 56.44 19.94 56.78z"
      ></path>
      <path
        fill="#bae0bd"
        d="M17.33 57.82c.28.63.58 1.24.91 1.83-.21.04-.43.08-.65.11-.14.02-.27.04-.41.04-.32-.59-.62-1.2-.89-1.83-.31-.69-.58-1.4-.83-2.12.15-.02.3-.05.46-.07.19-.03.38-.05.57-.06C16.73 56.44 17.02 57.14 17.33 57.82zM20.97 58.73c-.26.15-.57.3-.92.43-.26-.46-.51-.92-.75-1.4-.34-.68-.65-1.39-.91-2.11.39.02.76.05 1.11.11.29.72.62 1.43.99 2.12C20.64 58.17 20.8 58.45 20.97 58.73z"
      ></path>
      <path
        fill="#5e9c76"
        d="M18.24 59.65c.38.7.8 1.37 1.26 2.02-.32.07-.65.14-1 .19-.03 0-.05.01-.08.01-.82-1.23-1.54-2.53-2.13-3.9.1-.03.2-.04.3-.06.25-.04.49-.07.74-.09C17.61 58.45 17.91 59.06 18.24 59.65zM22.17 60.55c-.24.18-.53.35-.87.51-.45-.61-.87-1.24-1.25-1.9-.26-.46-.51-.92-.75-1.4.42.01.82.05 1.19.12.15.29.31.57.48.85.11.2.23.39.35.58.13.2.26.4.39.59C21.86 60.12 22.01 60.34 22.17 60.55z"
      ></path>
      <path
        fill="#5e9c76"
        d="M23.77,62.63c-0.29-1.79-0.28-3.82,0.02-6.04l0.03-0.24l-0.17-0.17c-2.04-2.13-3.66-4.4-4.81-6.74 c-0.82-1.65-1.38-3.29-1.7-4.89c-0.51-0.84-0.95-1.7-1.3-2.58c0.07,2.53,0.77,5.21,2.1,7.91c0.33,0.66,0.69,1.31,1.09,1.96 c0.4,0.67,0.85,1.34,1.34,1.99c0.13,0.18,0.26,0.36,0.4,0.54c0.19,0.24,0.38,0.48,0.59,0.72v0.01c0.44,0.54,0.91,1.07,1.4,1.59 c-0.08,0.7-0.14,1.37-0.17,2.03c-0.03,0.5-0.04,1-0.02,1.48c0,0.32,0.01,0.63,0.03,0.94c-0.15-0.19-0.3-0.39-0.43-0.59 c-0.16-0.21-0.31-0.43-0.46-0.65c-0.078-0.12-0.156-0.237-0.235-0.355l-0.923,0.428c0.239,0.37,0.486,0.733,0.748,1.087 c0.49,0.69,1.03,1.36,1.6,1.99l1.13,1.24L23.77,62.63z"
      ></path>
      <path
        fill="#5e9c76"
        d="M62.24,58.74c-4.2,8.31-12.85,13.65-22.28,13.65c-3.61,0-7.12-0.77-10.42-2.29 c-4.12-1.89-7.57-4.81-10.04-8.43c-0.231-0.326-0.452-0.658-0.662-0.995l-0.892,0.413c0.16,0.259,0.305,0.529,0.474,0.782 c2.6,3.93,6.28,7.1,10.71,9.14c3.43,1.58,7.07,2.38,10.83,2.38c10.06,0,19.42-6.15,23.66-15.19C63.21,58.28,62.65,58.66,62.24,58.74 z"
      ></path>
      <path
        fill="#fff"
        d="M44.95,29.19c-0.34,0.15-0.68,0.33-1.02,0.53C44.26,29.45,44.6,29.27,44.95,29.19z"
      ></path>
      <path
        fill="#d8e7fa"
        d="M45.09 31c-.66.12-1.31.26-1.93.4l.25-.64.19-.48c.77-.18 1.54-.34 2.3-.47.21-.03.42-.07.62-.1.23.26.41.6.55 1C46.4 30.79 45.74 30.89 45.09 31zM47.35 32.46c.01.32-.01.66-.06 1.01-.31.04-.62.09-.93.15-.71.12-1.44.28-2.17.47-.45.11-.91.24-1.36.37l-.98.29c0 0 .43-1.07.49-1.23l1.18-.29c.28-.08.56-.15.84-.22.74-.17 1.47-.32 2.19-.43C46.82 32.53 47.08 32.49 47.35 32.46z"
      ></path>
      <path
        fill="#4e7ab5"
        d="M54.47,29.77c-0.94-0.75-1.95-1.44-3.03-2.05c-2.32-1.25-5.76-0.49-9.46,2.03 c-3.3,2.26-6.53,5.69-9.32,9.82c0.28,0.17,0.56,0.35,0.85,0.52c2.72-4.01,5.85-7.33,9.04-9.51c0.46-0.31,0.92-0.6,1.37-0.85 c0.01-0.01,0.01-0.01,0.01-0.01c0.34-0.2,0.68-0.38,1.02-0.53c2.4-1.16,4.54-1.39,6.01-0.59c0.01,0.01,0.02,0.01,0.03,0.02 c0.44,0.24,0.88,0.52,1.3,0.8c-0.4-0.02-0.8-0.04-1.22-0.05c-0.32-0.01-0.65,0-0.98,0.01c-0.212,0.005-0.431,0.026-0.646,0.039 l-0.444,1.102c0.102-0.007,0.209-0.024,0.311-0.03h0.06c0.76-0.05,1.5-0.07,2.21-0.05c0.85,0.01,1.64,0.07,2.34,0.19 c0.05,0,0.1,0.01,0.15,0.02L56.01,31L54.47,29.77z"
      ></path>
      <path
        fill="#b2cff5"
        d="M49.31,30.49c-0.67,0.04-1.36,0.11-2.05,0.2c-0.73,0.08-1.46,0.19-2.17,0.31 c0.24-0.46,0.52-0.86,0.81-1.19c0.73-0.12,1.46-0.23,2.18-0.3c0.22-0.02,0.45-0.04,0.67-0.05C48.98,29.73,49.17,30.08,49.31,30.49z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M48.08 29.51c-.3.32-.58.72-.82 1.18.71-.09 1.42-.16 2.11-.2.2-.42.45-.8.72-1.11C49.44 29.4 48.76 29.44 48.08 29.51zM51.07 29.37c-.32-.01-.65 0-.98.01.332-.013.658-.013.981-.009C51.07 29.371 51.07 29.37 51.07 29.37zM50.514 30.439c.362-.008.718-.009 1.066.001 0-.001-.001-.003-.002-.004C51.231 30.43 50.878 30.429 50.514 30.439z"
      ></path>
      <path
        fill="#4e7ab5"
        d="M60.04,36.28l-0.14-0.24c-1.11-1.8-3.13-3.01-5.83-3.54c-0.7-0.14-1.45-0.23-2.24-0.26 c-0.36-0.03-0.73-0.04-1.1-0.04s-0.76,0.01-1.15,0.03c-0.29,0.02-0.58,0.04-0.88,0.07c-0.083,0.008-0.17,0.022-0.253,0.03L48.213,33 l-0.109,0.371c0.138-0.016,0.281-0.037,0.417-0.051c0.35-0.04,0.69-0.07,1.03-0.08c0.4-0.03,0.8-0.04,1.18-0.04 c0.36,0,0.71,0.01,1.05,0.03c0.77,0.04,1.5,0.13,2.18,0.27c2.38,0.48,4.14,1.52,5.08,3.06l0.14,0.24c1.28,2.36,0.75,5.67-1.52,9.34 c-0.12,0.2-0.26,0.4-0.39,0.59c0.41,0,0.82-0.01,1.22-0.03l0.03-0.03C60.97,42.68,61.52,39.01,60.04,36.28z"
      ></path>
      <path
        fill="#b2cff5"
        d="M46.946,32.519c-0.132,0.021-0.263,0.037-0.396,0.061c-0.72,0.11-1.45,0.26-2.19,0.43 c-0.06,0.25-0.1,0.51-0.14,0.77c-0.01,0.1-0.03,0.21-0.03,0.31c0.73-0.19,1.46-0.35,2.17-0.47c0.142-0.025,0.278-0.035,0.42-0.058 L46.946,32.519z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M51.83 32.24c-.36-.03-.73-.04-1.1-.04.38 0 .737.027 1.1.047C51.83 32.245 51.83 32.242 51.83 32.24zM46.55 32.58c-.08.34-.15.68-.19 1.04.73-.13 1.46-.23 2.16-.3.04-.35.1-.69.18-1.02C48 32.36 47.28 32.45 46.55 32.58z"
      ></path>
      <path
        fill="#d8e7fa"
        d="M41.59 24.72c-.43.41-.9.74-1.37.97h-.18c-.2 0-.42.01-.63.02l-.96.04.19-1 .95-.06h.08C40.31 24.66 40.95 24.67 41.59 24.72zM43.29 22.09c-.09.31-.22.64-.4.97-.16-.03-.33-.04-.49-.06-.77-.08-1.54-.12-2.32-.12h-1.09l.18-.97.58-.02c.09 0 .18-.01.26-.01.26 0 .53 0 .79.01h.01c.8.03 1.59.09 2.38.19C43.22 22.08 43.26 22.09 43.29 22.09z"
      ></path>
      <path
        fill="#b2cff5"
        d="M43.57,24.96c-0.43,0.36-0.857,0.647-1.317,0.837c-0.18-0.03-0.393-0.037-0.573-0.057 c-0.54-0.04-1.08-0.06-1.63-0.05h-0.01c-0.28-0.23-0.43-0.58-0.45-1h0.08c0.65-0.03,1.3-0.02,1.95,0.03 C42.28,24.77,42.92,24.85,43.57,24.96z"
      ></path>
      <path
        fill="#4e7ab5"
        d="M64.31 38.82c-2.38-6.49-7.15-11.65-13.44-14.54-.58-.27-1.18-.52-1.77-.73-.62-.24-1.25-.45-1.89-.64-.59-.17-1.18-.32-1.78-.45-.05-.01-.11-.02-.16-.03-.246-.052-.49-.098-.735-.143l-.628.909c.231.037.461.075.692.124.1.02.19.03.29.06.66.12 1.31.28 1.96.47.64.18 1.27.38 1.89.62.58.21 1.15.45 1.72.71 6.04 2.78 10.63 7.75 12.92 13.98.79 2.17 1.27 4.4 1.44 6.64.33-.08.66-.17.98-.27C65.61 43.27 65.12 41.01 64.31 38.82zM30.605 30.393c-.028.003-.057.004-.085.007-.13.01-.25.02-.37.04-.46.05-.92.12-1.36.21-.6.1-1.19.25-1.74.42-.55.15-1.07.34-1.57.56-.57.24-1.12.51-1.63.82.23.25.47.49.71.74.46-.26.95-.5 1.47-.72.4-.16.82-.31 1.26-.44.45-.14.92-.26 1.41-.35.29-.06.58-.12.88-.16.19-.03.38-.06.57-.07.104-.016.213-.022.318-.035L30.605 30.393z"
      ></path>
      <path
        fill="#4e7ab5"
        d="M45.67,25.38l-0.85-0.18c-0.37-0.08-0.74-0.15-1.12-0.22c-0.04-0.01-0.09-0.01-0.13-0.02 c-0.27-0.046-0.542-0.064-0.811-0.1l-0.636,0.921c0.028,0.003,0.055,0.003,0.084,0.008c0.27,0.02,0.543,0.062,0.803,0.102 c-0.29,0.15-0.59,0.31-0.88,0.48c-0.58,0.32-1.16,0.68-1.74,1.08C36.8,29.9,33.3,33.6,30.31,38.03c0.27,0.19,0.54,0.38,0.82,0.56 c2.92-4.33,6.33-7.94,9.82-10.32c0.96-0.65,1.91-1.2,2.85-1.64c0.36-0.18,0.73-0.34,1.09-0.48l1.56-0.6L45.67,25.38z"
      ></path>
      <path
        fill="#b2cff5"
        d="M45.27,22.43c-0.07,0.3-2.13,0.64-2.87,0.57c-0.77-0.08-1.54-0.12-2.32-0.12 c0.08-0.14,0.17-0.29,0.27-0.43c0.14-0.2,0.29-0.38,0.45-0.56h0.01c0.8,0.03,1.59,0.09,2.38,0.19 C43.89,22.16,44.58,22.28,45.27,22.43z"
      ></path>
      <path
        fill="#8bb7f0"
        d="M45.67 25.38L44.82 25.2 45.668 25.382zM43.7 24.98c-.69-.12-1.38-.21-2.08-.26-.07.38-.05.73.06 1.02.44.04.89.09 1.33.15.21.007.56.095.56.095C43.563 25.733 43.616 25.273 43.7 24.98zM47.21 22.91c-.59-.17-1.18-.32-1.78-.45.596.125 1.19.278 1.779.452C47.21 22.911 47.21 22.911 47.21 22.91zM46.427 23.737c.141.039.283.071.423.113 0 0 0 0 0-.001C46.71 23.809 46.568 23.774 46.427 23.737zM45.43 22.46c-.74-.16-1.49-.29-2.24-.38-.29.28-.56.58-.79.92.74.07 1.47.17 2.2.32C44.85 23 45.13 22.71 45.43 22.46z"
      ></path>
      <path
        fill="#d8e7fa"
        d="M33.87 30.35l.84.06-.93.93-.22-.01c-.15-.01-.3-.01-.44-.02-.7-.02-1.3 0-1.86.02v.01h-.1c-.34.03-.68.06-1.01.11.05-.33.18-.68.37-1.05.19-.02.38-.04.57-.05.26-.02.53-.03.82-.04.45-.01.93-.01 1.47.01L33.87 30.35zM37.23 27.88v.01l-.91.92-1.24-.19-.39-.03c-.75-.07-1.47-.1-2.17-.09-.18 0-.35 0-.53.01C32 28.5 32 28.5 32.01 28.49c.42-.38.86-.72 1.31-.98.49.01.99.04 1.5.09.45.04.92.09 1.41.15L37.23 27.88z"
      ></path>
      <path
        fill="#b2cff5"
        d="M33.38,30.32c-0.54-0.02-1.02-0.02-1.47-0.01c-0.29,0.01-0.56,0.02-0.82,0.04 c-0.32,0.02-0.63,0.05-0.94,0.09c-0.16,0.39-0.36,0.76-0.57,1.08c0.51-0.08,1.04-0.14,1.58-0.18h0.1v-0.01 c0.56-0.02,1.16-0.04,1.86-0.02c0.23-0.24,0.44-0.51,0.64-0.79c0.04-0.06,0.08-0.11,0.11-0.17L33.38,30.32z"
      ></path>
      <path
        fill="#4e7ab5"
        d="M30.851,28.56l0.136-1.013c-0.029,0.002-0.058,0.001-0.086,0.003c-0.08,0.01-0.16,0.01-0.24,0.02 c-0.03,0-0.05,0-0.08,0.01c-0.96,0.07-1.88,0.21-2.77,0.39c-0.91,0.19-1.77,0.45-2.59,0.75c-1.17,0.42-2.24,0.95-3.22,1.58 c0.21,0.26,0.42,0.52,0.63,0.78c0.77-0.49,1.61-0.91,2.5-1.26c0.69-0.28,1.42-0.52,2.19-0.71c0.75-0.19,1.54-0.33,2.36-0.44 c0.31-0.03,0.63-0.07,0.95-0.09C30.701,28.573,30.777,28.567,30.851,28.56z"
      ></path>
      <path
        fill="#b2cff5"
        d="M32.6,27.5c-0.57-0.01-1.14,0.01-1.7,0.05c-0.08,0.01-0.16,0.01-0.24,0.02 c0.02,0.32,0.01,0.66-0.03,1.01c0.11-0.01,0.22-0.02,0.34-0.03c0.51-0.04,1.03-0.05,1.55-0.05c0.7-0.01,1.42,0.02,2.17,0.09 c0.09-0.35,0.14-0.69,0.13-0.99C34.05,27.53,33.31,27.5,32.6,27.5z"
      ></path>
      <g>
        <path
          fill="#8bb7f0"
          d="M27.05 31.071c.008-.002.016-.005.023-.007C27.066 31.066 27.057 31.068 27.05 31.071 27.05 31.07 27.05 31.071 27.05 31.071zM31.09 30.35c-.32.02-.63.05-.94.09-.16.39-.36.76-.57 1.08.51-.08 1.04-.14 1.58-.18h.1v-.01c.24-.31.46-.64.65-1.02C31.62 30.32 31.35 30.33 31.09 30.35zM28.683 30.667c-.119.023-.233.05-.35.076C28.449 30.717 28.564 30.689 28.683 30.667zM27.932 31.861c-.125.032-.249.063-.372.097C27.683 31.924 27.808 31.893 27.932 31.861z"
        ></path>
      </g>
      <g>
        <path
          fill="#8bb7f0"
          d="M30.63 28.58c-.539.043-1.063.107-1.576.186C29.567 28.687 30.089 28.622 30.63 28.58zM27.808 27.974c.909-.193 1.86-.328 2.852-.404-.99.07-1.94.21-2.85.4C27.809 27.971 27.809 27.972 27.808 27.974zM28.424 28.884c-.297.056-.593.112-.881.18C27.831 28.996 28.128 28.94 28.424 28.884zM30.9 27.55c-.08.01-.16.01-.24.02.02.32.01.66-.03 1.01.11-.01.22-.02.34-.03.51-.04 1.03-.05 1.55-.05.07-.35.1-.69.08-1C32.03 27.49 31.46 27.51 30.9 27.55z"
        ></path>
      </g>
    </svg>
  );
};
